/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostLegalDocumentMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
} from '@mui/material'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import CaseWorkflowStyledComponents from 'pages/CaseWorkflowPage/CaseWorkflowComponents/CaseWorkflowStyledComponents'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  DocumentJuridique,
  DocumentJuridiqueMonEtablissementCreatePayload,
  DocumentJuridiqueType,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const legalDocumentSchema = Yup.object().shape<Shape<DocumentJuridiqueMonEtablissementCreatePayload>>({
  Nom: Yup.string(),
}).required()

/* Component declaration ---------------------------------------------------- */
interface LegalDocumentsButtonProps {
  legalDocument?: DocumentJuridique;
  legalType: DocumentJuridiqueType;
}

const LegalDocumentsButton: React.FC<LegalDocumentsButtonProps> = ({ legalDocument, legalType }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)
  const [ file, setFile ] = useState<File>()

  const [
    submitLegalDocument,
  ] = usePostLegalDocumentMutation()

  const onSubmit = async (values: DocumentJuridiqueMonEtablissementCreatePayload, { setSubmitting, resetForm }: FormikHelpers<DocumentJuridiqueMonEtablissementCreatePayload>) => {
    setSubmitting(false)
    const response = await submitLegalDocument({ ...values, Fichier: file })
    if (isApiError(response)) {
      toast.error("Une erreur est survenue lors de l'envoi du document.")
    } else {
      resetForm()
      setOpen(false)
    }
  }

  const formikForm: FormikContextType<DocumentJuridiqueMonEtablissementCreatePayload> = useForm<DocumentJuridiqueMonEtablissementCreatePayload>(
    {
      initialValues: {
        DateDepot: legalDocument?.dateDepot || '',
        IsObligatoire: legalDocument?.isObligatoire || false,
        Nom: legalDocument?.nom || '',
        'TypeDocument.Id': legalType.id,
      },
      onSubmit: onSubmit,
      validationSchema: legalDocumentSchema,
    },
  )

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      const newFile = Object.values(e.target.files ?? {})[0]
      setFile(newFile)
      formikForm.setFieldValue('Nom', newFile.name)
    }
  }

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        onClick={onClick}
        disabled={isReadOnly}
      >
        {legalDocument ? 'Modifier' : 'Ajouter'}
      </CaseWorkflowStyledComponents.Button>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <CaseWorkflowStyledComponents.DialogTitle>
          {legalDocument ? 'Modifier le document juridique' : 'Ajouter un document juridique'}
          <CloseButton handleClose={onClose} />
        </CaseWorkflowStyledComponents.DialogTitle>
        <Form form={formikForm}>
          <CaseWorkflowStyledComponents.DialogContent>
            <div>
              <FormBoldTitle
                smaller
                required
              >
                Ajouter une pièce jointe
              </FormBoldTitle>
              <AttachmentButton
                name="legal-document"
                onChange={handleOnFileChange}
                accept=".pdf"
                multiple={false}
              >
                <Button
                  variant="outlined"
                  component="span"
                >
                  {file?.name || 'Choisir un fichier'}
                </Button>
              </AttachmentButton>
              <FormBoldTitle>
                Nom du document
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Nom du document"
                name="Nom"
              />
            </div>
          </CaseWorkflowStyledComponents.DialogContent>
          <CaseWorkflowStyledComponents.DialogAction>
            <LongButton
              variant="outlined"
              onClick={onClose}
            >
              Annuler
            </LongButton>
            <LongButton
              type="submit"
              variant="contained"
              disabled={formikForm.isSubmitting}
            >
              Valider
            </LongButton>
          </CaseWorkflowStyledComponents.DialogAction>
        </Form>
      </Dialog>
    </>
  )
}

export default LegalDocumentsButton
