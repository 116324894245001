/* DateUtils methods -------------------------------------------------------- */
const isValidDate = (d: unknown): d is Date => {
  return d instanceof Date &&
  !Number.isNaN(d.valueOf())
}

const dateToAPIStr = (pDate: Date): string => {
  return pDate.toISOString()
}

const dateToShortAPIStr = (pDate: Date): string => {
  return dateToAPIStr(pDate)
    .split('T')[0]
}

const APIStrToDate = (pDateStr: string): Date => {
  return new Date(pDateStr)
}

const APIStrToDateOrUndefined = (pDateStr?: string | null): Date | undefined => {
  if (pDateStr === undefined || pDateStr === null) {
    return
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return
  }

  return date
}

const APIStrToLocalDateString = (pDateStr?: string | null, options?: Intl.DateTimeFormatOptions): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return ''
  }

  return date.toLocaleDateString('fr-FR', options)
}

const APIStrToLocalTimeString = (pDateStr?: string | null, options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) return ''

  return date.toLocaleTimeString('fr-FR', options)
}

const dateStringToAPILocalTimeString = (pDateStr?: string | null): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return ''
  }

  return new Date().toISOString()
}

const formatStartEndDateSameDay = (values: {dateDebut: string; dateFin: string}) => {
  const dateDebut = APIStrToDateOrUndefined(values.dateDebut)!.toISOString()
  const dateFin = APIStrToDateOrUndefined(values.dateFin)!.toISOString()
  return ({ dateDebut, dateFin: `${dateDebut.split('T')[0]}T${dateFin.split('T')[1]}` })
}

/* Export DateUtils ------------------------------------ */
export default {
  isValidDate,
  dateToAPIStr,
  dateToShortAPIStr,
  APIStrToDate,
  APIStrToDateOrUndefined,
  APIStrToLocalDateString,
  APIStrToLocalTimeString,
  dateStringToAPILocalTimeString,
  formatStartEndDateSameDay,
}
