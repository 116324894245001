/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import {
  Badge,
  Tab,
  Tabs,
} from '@mui/material'
import RouteTitle from 'router/RouteTitle'

/* Type imports ------------------------------------------------------------- */
import {
  MainTabs,
  mainTabs,
} from 'types/Tabs'

/* Styled components -------------------------------------------------------- */
const LabelContainer = styled.div`
  padding: 3px 13px 5px 0px;

  @media ${(props) => props.theme.media.tablet} {
    padding: 3px 20px 5px 0px;
  }

  @media ${(props) => props.theme.media.desktop} {
    padding: 10px 10px 10px 0px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutNavTabsProps {
  messagesLength: number;
  closeDrawer: () => void;
  vertical?: boolean;
}

const MainLayoutNavTabs: React.FC<MainLayoutNavTabsProps> = ({
  messagesLength,
  closeDrawer,
  vertical,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ tabValue, setTabValue ] = useState<number | boolean>(false)
  const [ title, setTitle ] = useState<string>('')

  useEffect(() => {
    const newTabValue = mainTabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
    setTabValue(newTabValue)
    if (mainTabs[newTabValue]?.label) {
      setTitle(`${mainTabs[newTabValue].label} ${caseId}`)
    }
  }, [ location.pathname ])

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
    navigate(`/${mainTabs[newValue].path}/${mainTabs[newValue].rootOnly ? '' : caseId || ''}`)
    closeDrawer()
  }

  const label = (tab: string) => (tab.toLocaleLowerCase() === MainTabs.Messagerie as string && messagesLength > 0) ?
    <Badge
      badgeContent={messagesLength}
      invisible={messagesLength <= 0}
      color="error"
      anchorOrigin={
        {
          vertical: 'top',
          horizontal: 'right',
        }
      }
    >
      <LabelContainer>
        {tab}
      </LabelContainer>
    </Badge> :
    tab

  return (
    <>
      <RouteTitle title={title} />
      <Tabs
        value={tabValue === -1 ? false : tabValue}
        orientation={vertical ? 'vertical' : 'horizontal'}
      >
        {
          mainTabs.map((tab, index) =>
            (!tab.caseOnly || caseId) &&
              <Tab
                key={`${tab.path}-${index}`}
                onClick={() => handleTabChange(index)}
                label={label(tab.label)}
              />,
          )
        }
      </Tabs>
    </>
  )
}

export default MainLayoutNavTabs
