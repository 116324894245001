/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowDot from '../CaseWorkflowDot'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'
import CaseWorkflowAddDocumentButton from '../CaseWorflowButtons/CaseWorkflowAddDocumentButton'
import CaseWorkflowAddImagesButton from '../CaseWorflowButtons/CaseWorkflowAddImagesButton'
import CaseWorkflowAddBothButton from '../CaseWorflowButtons/CaseWorkflowAddBothButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonPostRdvMetre } from 'API/__generated__/Api'
import {
  MediathequeType,
  TypeDocument,
  WorkflowEtat,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowPostMeasureJalonCardProps {
  jalon: JalonPostRdvMetre;
  caseId: string;
}

const CaseWorkflowPostMeasureJalonCard: React.FC<CaseWorkflowPostMeasureJalonCardProps> = ({
  jalon,
  caseId,
}) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Post RDV métré
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateValidation || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonPhotoAvantTravaux.etat}
            small
          />
          Photos avant travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonPhotoAvantTravaux.photoAvantTravaux.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonPhotoAvantTravaux.photoAvantTravaux.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.fileName || '')}
                      url={file.url || ''}
                      id={file.id || ''}
                      isPhoto
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
              <CaseWorkflowAddImagesButton
                caseId={caseId}
                type={MediathequeType.PhotoAvantTravaux}
                small
              />
            </CaseWorkflowStyledComponents.PicturesContainer> :
            jalon.sousJalonPhotoAvantTravaux.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddImagesButton
                caseId={caseId}
                type={MediathequeType.PhotoAvantTravaux}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonPhotoAvantTravaux"
          date={jalon.sousJalonPhotoAvantTravaux.dateValidation || ''}
          state={jalon.sousJalonPhotoAvantTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonBonDeCommande.etat}
            small
          />
          Mandat
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonBonDeCommande.bonDeCommande ?
            <CaseWorkflowDownloadButton
              name={getCleanFilename(jalon.sousJalonBonDeCommande.bonDeCommande.libelle || '', jalon.sousJalonBonDeCommande.bonDeCommande.nomDuFichier || '')}
              url={jalon.sousJalonBonDeCommande.bonDeCommande.url || ''}
              id={jalon.sousJalonBonDeCommande.bonDeCommande.id || ''}
            /> :
            jalon.sousJalonBonDeCommande.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddDocumentButton
                caseId={caseId}
                type={TypeDocument.BonDeCommande}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonBonDeCommande"
          date={jalon.sousJalonBonDeCommande.dateValidation || ''}
          state={jalon.sousJalonBonDeCommande.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonDocumentsSupplementaires.etat}
            small
          />
          <CaseWorkflowStyledComponents.SubLine>
            Documents supplémentaires
            <i>
              {' (facultatif)'}
            </i>
          </CaseWorkflowStyledComponents.SubLine>
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonDocumentsSupplementaires.documentsSupplementaires && jalon.sousJalonDocumentsSupplementaires.documentsSupplementaires.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonDocumentsSupplementaires.documentsSupplementaires.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.fileName || '')}
                      url={file.url || ''}
                      id={file.id || ''}
                      isPhoto={file.contentType.includes('image')}
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
              <CaseWorkflowAddBothButton
                caseId={caseId}
                small
              />
            </CaseWorkflowStyledComponents.PicturesContainer> :
            jalon.sousJalonDocumentsSupplementaires.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddBothButton caseId={caseId} /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonDocumentsSupplementaires"
          date={jalon.sousJalonDocumentsSupplementaires.dateValidation || ''}
          state={jalon.sousJalonDocumentsSupplementaires.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowPostMeasureJalonCard
