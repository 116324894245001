/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostNewMessageMutation } from 'store/api'
import { useIsMobileLandscape } from 'helpers/hooks/useIsMedia'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'

/* Type declarations -------------------------------------------------------- */
const messageSchema = Yup.object({
  objet: Yup.string().required("L'objet est obligatoire"),
  message: Yup.string().required('Le message est obligatoire'),
})

type MessageRequest = Yup.InferType<typeof messageSchema>

/* Styled components -------------------------------------------------------- */
const ButtonLine = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    gap: 0px;
    justify-content: space-between;
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    margin-top: 10px;
  }
`

const RecipientObjectContainer = styled.div`
  @media ${(props) => props.theme.media.mobile.landscape} {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    margin-bottom: -15px;

    .new-message-input {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`

const CustomTextField = styled(TextField)`
  @media ${(props) => props.theme.media.mobile.landscape} {
    margin-top: -5px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface MessagesNewMessageProps {
  caseId: string;
  setExpanded: (value: boolean) => void;
}

const MessagesNewMessage: React.FC<MessagesNewMessageProps> = ({
  caseId,
  setExpanded,
}) => {
  const isMobileLandscape = useIsMobileLandscape()

  const [
    submitNewMessage,
  ] = usePostNewMessageMutation()

  const onSubmit = async (values: MessageRequest, { setSubmitting, resetForm }: FormikHelpers<MessageRequest>): Promise<void> => {
    const response = await submitNewMessage({
      caseId,
      data: {
        ...values,
      },
    })

    if (!isApiError(response)) {
      resetForm()
      setExpanded(false)
    } else {
      toast.error("Erreur lors de l'envoi du message.")
      setSubmitting(false)
    }
  }

  const formikForm = useForm<MessageRequest>(
    {
      initialValues: {
        objet: '',
        message: '',
      },
      onSubmit: onSubmit,
      validationSchema: messageSchema,
    },
  )

  const resetForm = () => {
    formikForm.resetForm()
    setExpanded(false)
  }

  return (
    <LoadingOverlay isLoading={formikForm.isSubmitting}>
      <Form form={formikForm}>
        <RecipientObjectContainer>
          <div>
            <FormBoldTitle
              className="new-message-input"
              required
            >
              Objet
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Objet"
              name="objet"
              size="small"
            />
          </div>
        </RecipientObjectContainer>
        <FormBoldTitle required>
          Message
        </FormBoldTitle>
        <Field
          component={CustomTextField}
          placeholder="Votre message"
          rows={isMobileLandscape ? 2 : 3}
          multiline
          name="message"
          size="small"
        />
        <ButtonLine>
          <LongButton
            variant="outlined"
            onClick={resetForm}
            size={isMobileLandscape ? 'small' : 'medium'}
          >
            Annuler
          </LongButton>
          <LongButton
            type="submit"
            variant="contained"
            size={isMobileLandscape ? 'small' : 'medium'}
          >
            Envoyer
          </LongButton>
        </ButtonLine>
      </Form>
    </LoadingOverlay>
  )
}

export default MessagesNewMessage
