/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { usePatchIssueMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import { WarningAmberRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowIssueJalonText from '../CaseWorkflowIssueJalonText'

/* Type imports ------------------------------------------------------------- */
import type { Anomalie } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const IssueTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${(props) => props.theme.palette.warning.main};
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowIssueJalonCardProps {
  issue: Anomalie;
}

const CaseWorkflowIssueJalonCard: React.FC<CaseWorkflowIssueJalonCardProps> = ({ issue }) => {
  const isReadOnly = useIsReadOnly()

  const [ submitUpdateIssue ] = usePatchIssueMutation()

  const resolveIssue = () => {
    submitUpdateIssue({ id: issue.id, data: { statut: true, dateFin: new Date().toUTCString() }})
      .then((response) => {
        if (isApiError(response)) {
          toast.error('La difficulté ne peut pas être levée.')
        } else {
          toast.success('La difficulté a bien été levée.')
        }
      }).catch(console.error)
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          <IssueTitleContainer>
            <WarningAmberRounded color="warning" />
            {issue.anomalieType.nom}
          </IssueTitleContainer>
        </CaseWorkflowStyledComponents.Title>
        {
          issue?.statut === false ?
            <CaseWorkflowStyledComponents.Button
              variant="outlined"
              onClick={resolveIssue}
              disabled={isReadOnly}
            >
              Lever la difficulté
            </CaseWorkflowStyledComponents.Button> :
            <div />
        }
        <CaseWorkflowIssueJalonText
          state={issue.statut}
          endDate={issue.dateFin || ''}
          startDate={issue.dateDebut}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowIssueJalonCard
