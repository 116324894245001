/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostVacationMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Dialog } from '@mui/material'
import { toast } from 'react-toastify'
import CaseWorkflowStyledComponents from 'pages/CaseWorkflowPage/CaseWorkflowComponents/CaseWorkflowStyledComponents'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  EtablissementConge,
  EtablissementCongeRequest,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const vacationSchema = Yup.object().shape<Shape<EtablissementCongeRequest>>({
  dateDebut: Yup.string()
    .test('dateDebut',
      'La date de debut ne peut pas être superieur à la date de fin',
      (value, { parent }: {parent: EtablissementCongeRequest}) => new Date(value || '') < new Date(parent.dateFin || ''),
    ).required('La date de fin est obligatoire'),
  dateFin: Yup.string()
    .test('dateFin',
      "La date de fin ne peut pas être inférieure à aujourd'hui",
      (dateFin) => new Date() < new Date(dateFin || ''),
    ).required('La date de fin est obligatoire'),
}).required()

/* Component declaration ---------------------------------------------------- */
interface VacationsButtonProps {
  vacation?: EtablissementConge;
}

const VacationsButton: React.FC<VacationsButtonProps> = ({ vacation }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)
  const [ submitVacation ] = usePostVacationMutation()

  const onSubmit = async (values: EtablissementCongeRequest, { setSubmitting, resetForm }: FormikHelpers<EtablissementCongeRequest>) => {
    setSubmitting(false)
    const response = await submitVacation(values)
    if (isApiError(response)) {
      toast.error("Une erreur est survenue lors de l'enregistrement des congés.")
    } else {
      resetForm()
      setOpen(false)
    }
  }

  const formikForm: FormikContextType<EtablissementCongeRequest> = useForm<EtablissementCongeRequest>(
    {
      initialValues: {
        dateDebut: vacation?.dateDebut || '',
        dateFin: vacation?.dateFin || '',
        id: vacation?.id,
      },
      onSubmit: onSubmit,
      validationSchema: vacationSchema,
    },
  )

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        onClick={onClick}
        disabled={isReadOnly}
      >
        {vacation ? 'Modifier' : 'Ajouter des congés'}
      </CaseWorkflowStyledComponents.Button>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
      >
        <CaseWorkflowStyledComponents.DialogTitle>
          {vacation ? 'Modifier les congés' : 'Nouveaux congés'}
          <CloseButton handleClose={onClose} />
        </CaseWorkflowStyledComponents.DialogTitle>
        <Form form={formikForm}>
          <CaseWorkflowStyledComponents.DialogContent>
            <div>
              <FormBoldTitle required>
                Date de début
              </FormBoldTitle>
              <FormikDatePicker name="dateDebut" />
            </div>
            <div>
              <FormBoldTitle required>
                Date de fin
              </FormBoldTitle>
              <FormikDatePicker name="dateFin" />
            </div>
          </CaseWorkflowStyledComponents.DialogContent>
          <CaseWorkflowStyledComponents.DialogAction>
            <LongButton
              variant="outlined"
              onClick={onClose}
            >
              Annuler
            </LongButton>
            <LongButton
              type="submit"
              variant="contained"
              disabled={formikForm.isSubmitting}
            >
              Valider
            </LongButton>
          </CaseWorkflowStyledComponents.DialogAction>
        </Form>
      </Dialog>
    </>
  )
}

export default VacationsButton
