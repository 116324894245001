/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowDot from '../CaseWorkflowDot'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'
import CaseWorkflowAddDocumentButton from '../CaseWorflowButtons/CaseWorkflowAddDocumentButton'
import CaseWorkflowAddImagesButton from '../CaseWorflowButtons/CaseWorkflowAddImagesButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonPostIntervention } from 'API/__generated__/Api'
import {
  MediathequeType,
  TypeDocument,
  WorkflowEtat,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowPostInterventionJalonCardProps {
  jalon: JalonPostIntervention;
  caseId: string;
}

const CaseWorkflowPostInterventionJalonCard: React.FC<CaseWorkflowPostInterventionJalonCardProps> = ({
  jalon,
  caseId,
}) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Post Intervention
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateValidation || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonPhotoAvantTravaux.etat}
            small
          />
          Photos avant travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonPhotoAvantTravaux.photoAvantTravaux.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonPhotoAvantTravaux.photoAvantTravaux.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.fileName || '')}
                      url={file.url || ''}
                      id={file.id || ''}
                      isPhoto
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
              <CaseWorkflowAddImagesButton
                caseId={caseId}
                type={MediathequeType.PhotoAvantTravaux}
                small
              />
            </CaseWorkflowStyledComponents.PicturesContainer> :
            jalon.sousJalonPhotoAvantTravaux.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddImagesButton
                caseId={caseId}
                type={MediathequeType.PhotoAvantTravaux}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonPhotoAvantTravaux"
          date={jalon.sousJalonPhotoAvantTravaux.dateValidation || ''}
          state={jalon.sousJalonPhotoAvantTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonPhotoApresTravaux.etat}
            small
          />
          Photos après travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonPhotoApresTravaux.photoApresTravaux.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonPhotoApresTravaux.photoApresTravaux.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.fileName || '')}
                      url={file.url || ''}
                      id={file.id || ''}
                      isPhoto
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
              <CaseWorkflowAddImagesButton
                caseId={caseId}
                type={MediathequeType.PhotoApresTravaux}
                small
              />
            </CaseWorkflowStyledComponents.PicturesContainer> :
            jalon.sousJalonPhotoApresTravaux.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddImagesButton
                caseId={caseId}
                type={MediathequeType.PhotoApresTravaux}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonPhotoApresTravaux"
          date={jalon.sousJalonPhotoApresTravaux.dateValidation || ''}
          state={jalon.sousJalonPhotoApresTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonAttestationTva.etat}
            small
          />
          Attestation de TVA
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonAttestationTva.attestationTva?.length && jalon.sousJalonAttestationTva.attestationTva.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonAttestationTva.attestationTva.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.nomDuFichier || '')}
                      id={file.id || ''}
                      url={file.url || ''}
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
            </CaseWorkflowStyledComponents.PicturesContainer> :
            jalon.sousJalonAttestationTva.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddDocumentButton
                caseId={caseId}
                type={TypeDocument.AttestationTva}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonAttestationTva"
          date={jalon.sousJalonAttestationTva.dateValidation || ''}
          state={jalon.sousJalonAttestationTva.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonBonDeCommande.etat}
            small
          />
          Mandat
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonBonDeCommande.bonDeCommande ?
            <CaseWorkflowDownloadButton
              name={getCleanFilename(jalon.sousJalonBonDeCommande.bonDeCommande.libelle || '', jalon.sousJalonBonDeCommande.bonDeCommande.nomDuFichier || '')}
              id={jalon.sousJalonBonDeCommande.bonDeCommande.id || ''}
              url={jalon.sousJalonBonDeCommande.bonDeCommande.url || ''}
            /> :
            jalon.sousJalonBonDeCommande.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddDocumentButton
                caseId={caseId}
                type={TypeDocument.BonDeCommande}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonBonDeCommande"
          date={jalon.sousJalonBonDeCommande.dateValidation || ''}
          state={jalon.sousJalonBonDeCommande.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonAttestationFinDeTravaux.etat}
            small
          />
          PV de fin de chantier
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux ?
            <CaseWorkflowDownloadButton
              name={getCleanFilename(jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.libelle || '', jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.nomDuFichier || '')}
              url={jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.url || ''}
              id={jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.id || ''}
            /> :
            jalon.sousJalonAttestationFinDeTravaux.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddDocumentButton
                caseId={caseId}
                type={TypeDocument.AttestationFinDeTravaux}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonAttestationFinDeTravaux"
          date={jalon.sousJalonAttestationFinDeTravaux.dateValidation || ''}
          state={jalon.sousJalonAttestationFinDeTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonFactureComplementaire.etat}
            small
          />
          <div>
            Facture complémentaire
            <i>
              {' (Uniquement la ou les factures de reste à charge client (hors PEC))'}
            </i>
          </div>
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonFactureComplementaire.factureComplementaire?.length && jalon.sousJalonFactureComplementaire.factureComplementaire.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonFactureComplementaire.factureComplementaire.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.nomDuFichier || '')}
                      id={file.id || ''}
                      url={file.url || ''}
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
            </CaseWorkflowStyledComponents.PicturesContainer> :
            jalon.sousJalonFactureComplementaire.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowAddDocumentButton
                caseId={caseId}
                type={TypeDocument.FactureComplementaire}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonFactureComplementaire"
          date={jalon.sousJalonFactureComplementaire.dateValidation || ''}
          state={jalon.sousJalonFactureComplementaire.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowPostInterventionJalonCard
