/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useIsMobile } from 'helpers/hooks/useIsMedia'
import { useGetMessageListQuery } from 'store/api'
import {
  useAuthInfo,
  useIsReadOnly,
} from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Collapse,
} from '@mui/material'
import { CreateOutlined } from '@mui/icons-material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import CloseButton from 'components/CloseButton/CloseButton'
import MessagesCard from './MessagesComponents/MessagesCard'
import MessagesNewMessage from './MessagesComponents/MessagesNewMessage'
import MessagesAnswerMessage from './MessagesComponents/MessagesAnswerMessage'

/* Type imports ------------------------------------------------------------- */
import type { EchangeInterne } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`

interface InterfaceContainerProps {
  isExternal: boolean;
}

const InterfaceContainer = styled.div<InterfaceContainerProps>`
  max-height: calc(100vh - ${(props) => props.isExternal ? '370px' : '290px'});
  position: relative;
  overflow-y: auto;

  @media ${(props) => props.theme.media.mobile.landscape} {
    max-height: calc(100vh - 150px);
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    max-height: calc(100vh - 190px);
  }
`

const NewMessageContainer = styled.div`
  position: sticky;
  bottom: -1px;
  width: 100%;
`

const CardContent = styled.div`
  padding: 15px;

  @media ${(props) => props.theme.media.mobile.main} {
    padding: 10px;
  }
`

const NewMessageButton = styled(Button)`
  margin: -10px 0px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin: -5px 0px -5px;
  }
`

const NewMessageIcon = styled(CreateOutlined)`
  margin-left: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface MessagesPageProps {
  externalCaseId?: string;
}

const MessagesPage: React.FC<MessagesPageProps> = ({ externalCaseId = '' }) => {
  const isMobile = useIsMobile()
  const auth = useAuthInfo()
  const isReadOnly = useIsReadOnly()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ expanded, setExpanded ] = useState<boolean>(false)
  const [ reply, setReply ] = useState<EchangeInterne | null>(null)

  const {
    currentData: messages = [],
    isFetching: isFetchingMessages,
  } = useGetMessageListQuery({ dossier: externalCaseId ? externalCaseId : caseId }, { skip: !externalCaseId && !caseId })

  useEffect(() => {
    if (!isFetchingMessages) {
      // const unreadMessage = document.getElementById('message-unread')
      const interfaceContainer = document.getElementById('interface-container')
      interfaceContainer?.scroll({ top: interfaceContainer?.scrollHeight || 0, behavior: 'smooth' })
      // TODO : scroll to first unread msg
      // if (unreadMessage === null) {
      // interfaceContainer?.scroll({ top: interfaceContainer?.scrollHeight || 0, behavior: 'smooth' })
      // } else {
      //   unreadMessage.scrollIntoView({ block: 'start', inline: 'nearest', behavior: isMobile ? 'auto' : 'smooth' })
      // }
    }
  }, [ messages, isFetchingMessages, isMobile, externalCaseId ])

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  const isMe = (msg: EchangeInterne) => msg.emetteur.id === auth?.idAnnuaire

  const onReplyClick = (message: EchangeInterne) => {
    setExpanded(false)
    setReply(message)
  }

  const handleClose = () => {
    setExpanded(false)
    setReply(null)
  }

  return (
    <div>
      <LargeTitle>
        Messagerie
      </LargeTitle>
      <LoadingOverlay isLoading={isFetchingMessages}>
        {
          (caseId || externalCaseId) &&
            <InterfaceContainer
              id="interface-container"
              isExternal={!!externalCaseId}
            >
              {
                [ ...messages ].sort((a, b) => a.date.localeCompare(b.date)).map((message, index) => (
                  <MessageContainer
                    key={`${message.id}-${index}`}
                    id={`message-${isMe(message) && !message.lu ? 'unread' : 'read'}`}
                  >
                    <MessagesCard
                      myId={auth?.idAnnuaire || ''}
                      message={message}
                      onReply={() => onReplyClick(message)}
                    />
                  </MessageContainer>
                ))
              }
              <NewMessageContainer>
                <Card>
                  <CardContent>
                    {
                      !expanded && reply === null ?
                        <NewMessageButton
                          onClick={handleExpandClick}
                          disabled={isReadOnly}
                        >
                          Écrire un nouveau message...
                          <NewMessageIcon />
                        </NewMessageButton> :
                        <CloseButton handleClose={handleClose} />
                    }
                    <Collapse
                      in={expanded}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MessagesNewMessage
                        caseId={externalCaseId ? externalCaseId : caseId}
                        setExpanded={setExpanded}
                      />
                    </Collapse>
                    <Collapse
                      in={reply !== null}
                      timeout="auto"
                      unmountOnExit
                    >
                      {
                        reply !== null &&
                          <MessagesAnswerMessage
                            message={reply}
                            closeReply={() => setReply(null)}
                          />
                      }
                    </Collapse>
                  </CardContent>
                </Card>
              </NewMessageContainer>
            </InterfaceContainer>
        }
      </LoadingOverlay>
      <Footer />
    </div>
  )
}

export default MessagesPage
