/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetVacationsQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import VacationsButton from './VacationsComponents/VacationsButton'
import VacationsCard from './VacationsComponents/VacationsCard'

/* Styled components -------------------------------------------------------- */
const VacationsCardContainer = styled.div`
  padding-bottom: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface VacationPageProps {}

const VacationPage: React.FC<VacationPageProps> = () => {

  const {
    currentData: vacationList = [],
    isFetching: isFetchingVacationList,
  } = useGetVacationsQuery()

  return (
    <div>
      <LargeTitle>
        Congés
        <VacationsButton />
      </LargeTitle>
      {
        isFetchingVacationList ?
          <CircularProgress /> :
          vacationList.map((vacation) => (
            <VacationsCardContainer key={vacation.id}>
              <VacationsCard vacation={vacation} />
            </VacationsCardContainer>
          ))
      }
      <Footer />
    </div>
  )
}

export default VacationPage
