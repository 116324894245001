/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { getCaseStatusColor } from 'helpers/caseStatusColor'
import {
  useGetCaseInfosQuery,
  useGetIssuesListQuery,
} from 'store/api'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Chip,
} from '@mui/material'
import { ArrowBackIosNewRounded } from '@mui/icons-material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type declarations -------------------------------------------------------- */
import type { Anomalie } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const LayoutContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    justify-content: space-between;
  }
`

const CaseTitle = styled(Button)`
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: .95rem;
  padding: 0px;
  gap: 5px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: .9rem;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutTitleStatusProps {
  caseId: string;
}

const CaseLayoutTitleStatus: React.FC<CaseLayoutTitleStatusProps> = ({ caseId }) => {
  const navigate = useNavigate()
  const authInfo = useAuthInfo()
  const [ lastUnsolvedIssue, setLastUnsolvedIssue ] = useState<Anomalie | undefined>()
  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)
  const {
    currentData: issues = [],
    isFetching: isFetchingIssue,
  } = useGetIssuesListQuery(caseId)

  useEffect(() => {
    if (!isFetchingIssue && issues && issues.length > 0 ) {
      const last = issues.filter((iss) => !iss.statut)
      setLastUnsolvedIssue(last[last.length -1])
    }
  }, [ issues ])

  const handleGoBack = () => {
    navigate('/dossiers')
  }

  return (
    <LayoutContainer>
      <CaseTitle
        onClick={handleGoBack}
        color="secondary"
        size="small"
        disabled={authInfo?.lectureSeule || false}
      >
        <ArrowBackIosNewRounded fontSize="small" />
        {`Dossier ${caseId}`}
      </CaseTitle>
      {
        caseInfos?.statutComposeDossier?.code &&
          <ColoredSquareChip
            color={getCaseStatusColor(caseInfos?.statutComposeDossier.code)}
            smaller
          >
            {caseInfos?.statutComposeDossier.libelle}
          </ColoredSquareChip>
      }
      {
        lastUnsolvedIssue && (
          <Chip
            color="warning"
            label={lastUnsolvedIssue ? lastUnsolvedIssue.anomalieType?.nom : ''}
          />
        )
      }
    </LayoutContainer>
  )
}

export default CaseLayoutTitleStatus
