/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { usePostMediaFilesMutation } from 'store/api'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import {
  AddPhotoAlternate,
  NoteAdd,
} from '@mui/icons-material'
import ImportFilesModal from 'components/ImportFilesModal/ImportFilesModal'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type { MediathequeDossierCreatePayload } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const AddButton = styled(Button)`
  width: 60px;
  min-width: 60px !important;
  height: 43px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowAddBothButtonProps {
  caseId: string;
  small?: boolean;
}

const CaseWorkflowAddBothButton: React.FC<CaseWorkflowAddBothButtonProps> = ({ caseId, small }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const [
    submitNewDocument,
    { isLoading: isSendingFile },
  ] = usePostMediaFilesMutation()

  const onClose = (files?: File[]) => {
    setOpen(false)
    const data: MediathequeDossierCreatePayload = { elementMediathequeRequest: []}

    if (files && files.length > 0) {
      files.forEach((file) => {
        if (file !== undefined) {
          data.elementMediathequeRequest?.push({
            fichier: file,
            nom: file.name,
            categorie: 'DIV',
            sousCategorie: '',
          })
        }
      })

      submitNewDocument({ caseId, data }).catch(console.error)
    }
  }

  return (
    <React.Fragment>
      {
        small ?
          <AddButton
            variant="contained"
            onClick={() => setOpen(true)}
            disabled={isSendingFile || isReadOnly}
          >
            <AddPhotoAlternate />
            <NoteAdd />
          </AddButton> :
          <CaseWorkflowStyledComponents.Button
            variant="contained"
            disabled={isSendingFile || isReadOnly}
            fullWidth
            onClick={() => setOpen(true)}
          >
            Ajouter les photos ou documents
          </CaseWorkflowStyledComponents.Button>
      }
      {open && <ImportFilesModal handleClose={onClose} />}
    </React.Fragment>
  )
}

export default CaseWorkflowAddBothButton
