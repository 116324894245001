/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material'
import {
  Dropzone,
  FileMosaic,
} from '@dropzone-ui/react'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'

/* Type imports ------------------------------------------------------------- */
import type { ExtFile } from '@dropzone-ui/react'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const DropzoneContainer = styled.div`
  padding: 0px 20px 30px;
`

/* Component declaration ---------------------------------------------------- */
interface ImportFilesModalProps {
  handleClose: (files?: File[]) => void;
  maxFiles?: number;
  accept?: string;
}

const ImportFilesModal: React.FC<ImportFilesModalProps> = ({
  maxFiles,
  accept='image/*, application/pdf',
  handleClose,
}) => {
  const [ uploadedFiles, setUploadedFiles ] = useState<ExtFile[]>([])

  const updateFiles = (incommingFiles: ExtFile[]) => {
    setUploadedFiles(incommingFiles.filter((f) => f.valid))
  }

  const onValidate = () => {
    const files: File[] = []

    for (const file of uploadedFiles) {
      if (file.file) {
        files.push(file.file)
      }
    }
    handleClose(files)
  }

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleContainer>
        Importer
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DropzoneContainer>
        <Dropzone
          onChange={updateFiles}
          value={uploadedFiles}
          accept={accept}
          maxFiles={maxFiles}
          localization="FR-fr"
          label="Cliquez ou déposez vos fichiers ici"
          footer={false}
        >
          {
            uploadedFiles.map((file, index) => (
              <FileMosaic
                key={`${file.id}-${index}`}
                preview
                localization="FR-fr"
                onDelete={(id) => setUploadedFiles(uploadedFiles.filter((file) => file.id !== id))}
                {...file}
              />
            ))
          }
        </Dropzone>
      </DropzoneContainer>
      <DialogActionContainer>
        <LongButton
          variant="outlined"
          onClick={() => handleClose()}
        >
          Annuler
        </LongButton>
        <LongButton
          variant="contained"
          onClick={onValidate}
        >
          Valider
        </LongButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default ImportFilesModal
