/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { verifyNumberField } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'

/* Type imports ------------------------------------------------------------- */
import type { FieldAttributes } from 'formik'

/* Component declaration ---------------------------------------------------- */
interface NumberFieldProps {
  placeholder?: string;
  name: string;
  size?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const NumberField: React.FC<FieldAttributes<NumberFieldProps>> = ({
  name,
  placeholder = '0',
  ...props
}) => {

  return (
    <Field
      component={TextField}
      name={name}
      placeholder={placeholder}
      InputProps={
        {
          inputProps: {
            style: { textAlign: 'right' },
          },
        }
      }
      {...props}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => verifyNumberField(event, props.onChange)}
    />
  )
}

export default NumberField
