/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowDot from '../CaseWorkflowDot'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'
import CaseWorkflowAddDocumentButton from '../CaseWorflowButtons/CaseWorkflowAddDocumentButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonFinDeTravauxRDF } from 'API/__generated__/Api'
import {
  TypeDocument,
  WorkflowEtat,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWaterWorkDoneJalonCardProps {
  jalon: JalonFinDeTravauxRDF;
  caseId: string;
}

const CaseWorkflowWaterWorkDoneJalonCard: React.FC<CaseWorkflowWaterWorkDoneJalonCardProps> = ({
  jalon,
  caseId,
}) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Fin de travaux RDF
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateValidation || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      {
        jalon.sousJalonRapportRechercheDeFuite &&
          <CaseWorkflowStyledComponents.CardContent>
            <CaseWorkflowStyledComponents.Line>
              <CaseWorkflowDot
                state={jalon.sousJalonRapportRechercheDeFuite.etat}
                small
              />
              Rapport de recherche de fuite
            </CaseWorkflowStyledComponents.Line>
            {
              jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite ?
                <CaseWorkflowDownloadButton
                  name={getCleanFilename(jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.libelle || '', jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.nomDuFichier || '')}
                  url={jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.url || ''}
                  id={jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.id || ''}
                /> :
                jalon.sousJalonRapportRechercheDeFuite.etat !== WorkflowEtat.NonFait ?
                  <CaseWorkflowAddDocumentButton
                    caseId={caseId}
                    type={TypeDocument.RapportRechercheDeFuite}
                  /> :
                  <div />
            }
            <CaseWorkflowJalonText
              jalon="sousJalonRapportRechercheDeFuite"
              date={jalon.sousJalonRapportRechercheDeFuite.dateValidation || ''}
              state={jalon.sousJalonRapportRechercheDeFuite.etat}
            />
          </CaseWorkflowStyledComponents.CardContent>
      }
      {
        jalon.sousJalonBonDeCommande &&
          <CaseWorkflowStyledComponents.CardContent>
            <CaseWorkflowStyledComponents.Line>
              <CaseWorkflowDot
                state={jalon.sousJalonBonDeCommande.etat}
                small
              />
              Mandat
            </CaseWorkflowStyledComponents.Line>
            {
              jalon.sousJalonBonDeCommande.bonDeCommande ?
                <CaseWorkflowDownloadButton
                  name={getCleanFilename(jalon.sousJalonBonDeCommande.bonDeCommande.libelle || '', jalon.sousJalonBonDeCommande.bonDeCommande.nomDuFichier || '')}
                  url={jalon.sousJalonBonDeCommande.bonDeCommande.url || ''}
                  id={jalon.sousJalonBonDeCommande.bonDeCommande.id || ''}
                /> :
                jalon.sousJalonBonDeCommande.etat !== WorkflowEtat.NonFait ?
                  <CaseWorkflowAddDocumentButton
                    caseId={caseId}
                    type={TypeDocument.BonDeCommande}
                  /> :
                  <div />
            }
            <CaseWorkflowJalonText
              jalon="sousJalonBonDeCommande"
              date={jalon.sousJalonBonDeCommande.dateValidation || ''}
              state={jalon.sousJalonBonDeCommande.etat}
            />
          </CaseWorkflowStyledComponents.CardContent>
      }
    </Card>
  )
}

export default CaseWorkflowWaterWorkDoneJalonCard
