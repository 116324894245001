/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { usePatchWorkflowMissionStateMutation } from 'store/api'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'
import CaseWorkflowRefuseMissionButton from '../CaseWorflowButtons/CaseWorkflowRefuseMissionButton'

/* Type imports ------------------------------------------------------------- */
import type { JalonMissionnement } from 'API/__generated__/Api'
import {
  EtatMissionnement,
  WorkflowEtat,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowMissionJalonCardProps {
  jalon: JalonMissionnement;
  caseId: string;
  isUrgentCase: boolean;
}

const CaseWorkflowMissionJalonCard: React.FC<CaseWorkflowMissionJalonCardProps> = ({
  jalon,
  caseId,
  isUrgentCase,
}) => {
  const isReadOnly = useIsReadOnly()

  const [
    submitUpdateMission,
    { isLoading: isSendingState },
  ] = usePatchWorkflowMissionStateMutation()

  const onClick = (etat: EtatMissionnement) => {
    submitUpdateMission({ caseId, data: { etat }}).catch(console.error)
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Missionnement
        </CaseWorkflowStyledComponents.Title>
        {
          jalon.etat === WorkflowEtat.EnAttente ?
            <CaseWorkflowStyledComponents.DuoButtonsContainer>
              <CaseWorkflowRefuseMissionButton
                caseId={caseId}
                isUrgentCase={isUrgentCase}
              />
              <CaseWorkflowStyledComponents.Button
                variant="contained"
                disabled={isSendingState || isReadOnly}
                onClick={() => onClick(EtatMissionnement.Accepte)}
              >
                Accepter
              </CaseWorkflowStyledComponents.Button>
            </CaseWorkflowStyledComponents.DuoButtonsContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.missionnement?.dateModificationEtat || ''}
          state={jalon.etat}
          missionState={jalon.missionnement?.etatMissionnement}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowMissionJalonCard
