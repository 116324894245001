/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  useGetCaseWorkflowQuery,
  useGetIssuesListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CaseWorkflowJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflowJalonCard'
import CaseWorkflowWorkEndJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow8WorkDoneJalonCard'
import CaseWorkflowDot from './CaseWorkflowComponents/CaseWorkflowDot'
import CaseWorkflowInterventionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow2InterventionJalonCard'
import CaseWorkflowPostInterventionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow3PostInterventionJalonCard'
import CaseWorkflowMissionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow1MissionJalonCard'
import CaseWorkflowPostMeasureJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow5PostMeasureJalonCard'
import CaseWorkflowContactJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow2ContactJalonCard'
import CaseWorkflowMeasureRdvJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow3MeasureRdvJalonCard'
import CaseWorkflowWorksRdvJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow7WorksRdvJalonCard'
import CaseWorkflowWaterWorkDoneJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow8WaterWorkDoneJalonCard'
import CaseWorkflowIssueJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflowIssueJalonCard'
import CaseWorkflowReportIssueButton from './CaseWorkflowComponents/CaseWorflowButtons/CaseWorkflowReportIssueButton'

/* Type imports ------------------------------------------------------------- */
import {
  WorkflowEtat,
  EtatMissionnement,
  WorkflowJalon,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface LineContainerProps {
  last?: boolean;
}

const LineContainer = styled.div<LineContainerProps>`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => props.theme.colors.grey};
  margin-left: 10px;
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '30px'};

  .MuiPaper-root {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowPageProps {}

const CaseWorkflowPage: React.FC<CaseWorkflowPageProps> = () => {
  const { caseId = '' } = useParams<{ caseId: string }>()

  const {
    currentData: workflow,
    isFetching: isFetchingWorkflow,
  } = useGetCaseWorkflowQuery(caseId)
  const {
    currentData: issues = [],
    isFetching: isFetchingIssue,
  } = useGetIssuesListQuery(caseId)

  const findJalon = (jalon: WorkflowJalon) => {
    if (!workflow) return
    switch (jalon) {
      case WorkflowJalon.Missionnement:
        if (!workflow.jalonMissionnement) return
        return (
          {
            jalon: workflow.jalonMissionnement,
            render: (
              <CaseWorkflowMissionJalonCard
                jalon={workflow.jalonMissionnement}
                caseId={caseId}
                isUrgentCase={workflow.dossierUrgent}
              />
            ),
          }
        )
      case WorkflowJalon.Intervention:
        if (!workflow.jalonIntervention) return
        return (
          {
            jalon: workflow.jalonIntervention,
            render: (
              <CaseWorkflowInterventionJalonCard
                jalon={workflow.jalonIntervention}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.PostIntervention:
        if (!workflow.jalonPostIntervention) return
        return (
          {
            jalon: workflow.jalonPostIntervention,
            render: (
              <CaseWorkflowPostInterventionJalonCard
                jalon={workflow.jalonPostIntervention}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.Contact:
        if (!workflow.jalonPriseDeContact) return
        return (
          {
            jalon: workflow.jalonPriseDeContact,
            render: (
              <CaseWorkflowContactJalonCard
                jalon={workflow.jalonPriseDeContact}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.RDVMetre:
        if (!workflow.jalonRDVMetre) return
        return (
          {
            jalon: workflow.jalonRDVMetre,
            render: (
              <CaseWorkflowMeasureRdvJalonCard
                jalon={workflow.jalonRDVMetre}
                jalonMeasureDone={workflow.jalonPostRdvMetre}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.PostRdvMetre:
        if (!workflow.jalonPostRdvMetre) return
        return (
          {
            jalon: workflow.jalonPostRdvMetre,
            render: (
              <CaseWorkflowPostMeasureJalonCard
                jalon={workflow.jalonPostRdvMetre}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.Devis:
        if (!workflow.jalonDevis) return
        return (
          {
            jalon: workflow.jalonDevis,
            render: (
              <CaseWorkflowJalonCard
                jalon={
                  {
                    ...workflow.jalonDevis,
                    libelle: (workflow.dossierUrgent ?
                      <div>
                        Devis complémentaire
                        <i>
                          {' (facultatif)'}
                        </i>
                      </div> :
                      'Devis') as string,
                    date: workflow.jalonDevis.dateAffichee || workflow.jalonDevis.dateModification || '',
                  }
                }
                quote={workflow.jalonDevis.devis}
                comment={workflow.jalonDevis.devis?.motifRefus || ''}
              />
            ),
          }
        )
      case WorkflowJalon.RDVTravaux:
        if (!workflow.jalonRDVTravaux) return
        return (
          {
            jalon: workflow.jalonRDVTravaux,
            render: (
              <CaseWorkflowWorksRdvJalonCard
                jalon={workflow.jalonRDVTravaux}
                jalonWorkDone={workflow.jalonFinDeTravaux || workflow.jalonFinDeTravauxRDF}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.FinDeTravaux:
        if (!workflow.jalonFinDeTravaux) return
        return (
          {
            jalon: workflow.jalonFinDeTravaux,
            render: (
              <CaseWorkflowWorkEndJalonCard
                jalon={workflow.jalonFinDeTravaux}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.FinDeTravauxRDF:
        if (!workflow.jalonFinDeTravauxRDF) return
        return (
          {
            jalon: workflow.jalonFinDeTravauxRDF,
            render: (
              <CaseWorkflowWaterWorkDoneJalonCard
                jalon={workflow.jalonFinDeTravauxRDF}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.CompteRenduVisite:
        if (!workflow.jalonCompteRendu) return
        return (
          {
            jalon: workflow.jalonCompteRendu,
            render: (
              <CaseWorkflowJalonCard
                jalon={
                  {
                    ...workflow.jalonCompteRendu,
                    libelle: 'Compte-rendu de visite Sinapps',
                    date: workflow.jalonCompteRendu.date || '',
                  }
                }
              />
            ),
          }
        )
      case WorkflowJalon.CompteRenduRDF:
        if (!workflow.jalonCompteRendu) return
        return (
          {
            jalon: workflow.jalonCompteRendu,
            render: (
              <CaseWorkflowJalonCard
                jalon={
                  {
                    ...workflow.jalonCompteRendu,
                    libelle: 'Compte-rendu RDF Sinapps',
                    date: workflow.jalonCompteRendu.date || '',
                  }
                }
              />
            ),
          }
        )
      case WorkflowJalon.Facture:
        if (!workflow.jalonFacture) return
        return (
          {
            jalon: workflow.jalonFacture,
            render: (
              <CaseWorkflowJalonCard
                jalon={
                  {
                    ...workflow.jalonFacture,
                    libelle: 'Facture',
                    date: workflow.jalonFacture.dateAffichee || workflow.jalonFacture.dateModification || '',
                  }
                }
                invoice={workflow.jalonFacture.facture}
                comment={workflow.jalonFacture.facture?.motifRefus || ''}
              />
            ),
          }
        )
      case WorkflowJalon.DossierTermine:
        if (!workflow.jalonDossierTermine) return
        return (
          {
            jalon: workflow.jalonDossierTermine,
            render: (
              <CaseWorkflowJalonCard
                jalon={workflow.jalonDossierTermine}
              />
            ),
          }
        )
      default:
        break
    }
  }

  return (
    <div>
      <LargeTitle>
        Suivi Dossier
        {
          workflow && workflow.jalonMissionnement.missionnement?.etatMissionnement === EtatMissionnement.Accepte &&
            <CaseWorkflowReportIssueButton caseId={caseId} />
        }
      </LargeTitle>
      {
        (isFetchingWorkflow || isFetchingIssue || !workflow) ?
          <CircularProgress /> :
          <>
            {
              workflow.order.map((order, index, { length }) => order === WorkflowJalon.Anomalies ?
                issues.map((issue, index: number) => (
                  <LineContainer key={index}>
                    <CaseWorkflowDot state={WorkflowEtat.Anomalie} />
                    <CaseWorkflowIssueJalonCard issue={issue} />
                  </LineContainer>
                )) :
                <LineContainer
                  key={order}
                  last={index === length - 1}
                >
                  <CaseWorkflowDot state={findJalon(order)?.jalon?.etat || WorkflowEtat.NonFait} />
                  {findJalon(order)?.render}
                </LineContainer>,
              )
            }
          </>
      }
      <Footer />
    </div>
  )
}

export default CaseWorkflowPage
