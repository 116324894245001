/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostWorksRdvMutation,
  usePatchWorksRdvMutation,
} from 'store/api'
import {
  isApiError,
  type ApiResponse,
} from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Dialog } from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  RendezVousTravaux,
  RendezVousTravauxRequest,
} from 'API/__generated__/Api'
import { WorkflowEtat } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type RdvRequest = RendezVousTravauxRequest

const rdvSchema = Yup.object().shape<Shape<RdvRequest>>({
  dateDebut: Yup.string().nullable().required('La date de début est obligatoire'),
  dateFin: Yup.string().nullable()
    .test('dateDebut',
      'La date de fin ne peut pas être inférieure à celle de début',
      (dateFin = '', { parent }: {parent: RendezVousTravaux}) => {
        const dates = { dateDebut: parent.dateDebut, dateFin: dateFin || '' }
        return new Date(dates.dateFin) > new Date(dates.dateDebut)
      },
    ).required('La date de fin est obligatoire'),
}).required()

type RdvForm = FormikContextType<RdvRequest>

/* Internal variables ------------------------------------------------------- */
const initialDay: RendezVousTravauxRequest = {
  dateDebut: new Date().toISOString(),
  dateFin: new Date().toISOString(),
}

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWorksRdvButtonProps {
  caseId: string;
  initialRdv?: RendezVousTravaux;
  state: WorkflowEtat;
}

const CaseWorkflowWorksRdvButton: React.FC<CaseWorkflowWorksRdvButtonProps> = ({
  caseId,
  initialRdv,
  state,
}) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const [
    submitNewRdv,
  ] = usePostWorksRdvMutation()
  const [
    submitEditRdv,
  ] = usePatchWorksRdvMutation()

  const onSubmit = (values: RdvRequest, { setSubmitting, resetForm }: FormikHelpers<RdvRequest>) => {
    const onFinish = (response: ApiResponse<void>) => {
      if (!isApiError(response)) {
        setOpen(false)
        setSubmitting(false)
        resetForm()
      } else {
        setSubmitting(false)
      }
    }

    if (initialRdv) {
      submitEditRdv({ id: initialRdv.id, data: values }).then(onFinish).catch(console.error)
    } else {
      submitNewRdv({ caseId, data: values }).then(onFinish).catch(console.error)
    }
  }

  const formikForm: RdvForm = useForm<RdvRequest>(
    {
      initialValues: initialDay,
      onSubmit: onSubmit,
      validationSchema: rdvSchema,
    },
  )

  useEffect(() => {
    if (!initialRdv) return
    formikForm.setValues(initialRdv)
  }, [ initialRdv ])

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        onClick={onClick}
        disabled={isReadOnly}
      >
        {state === WorkflowEtat.Fait ? 'Modifier les RDV' : state === WorkflowEtat.EnAttente ? 'Nouveaux RDV travaux' : 'Reprendre des RDV travaux'}
      </CaseWorkflowStyledComponents.Button>
      {
        open &&
          <Dialog
            open
            onClose={onClose}
            maxWidth="xl"
          >
            <CaseWorkflowStyledComponents.DialogTitle>
              {state === WorkflowEtat.Fait ? 'Modifier les RDV' : state === WorkflowEtat.EnAttente ? 'Nouveaux RDV travaux' : 'Reprendre des RDV travaux'}
              <CloseButton handleClose={onClose} />
            </CaseWorkflowStyledComponents.DialogTitle>
            <Form form={formikForm}>
              <CaseWorkflowStyledComponents.DialogContent>
                <GridContainer>
                  <div>
                    <FormBoldTitle required>
                      Date de début
                    </FormBoldTitle>
                    <FormikDatePicker name="dateDebut" />
                  </div>
                  <div>
                    <FormBoldTitle required>
                      Date de fin
                    </FormBoldTitle>
                    <FormikDatePicker name="dateFin" />
                  </div>
                </GridContainer>
                <GridContainer>
                  <div>
                    <FormBoldTitle required>
                      Heure de début
                    </FormBoldTitle>
                    <FormikDatePicker
                      name="dateDebut"
                      time
                    />
                  </div>
                  <div>
                    <FormBoldTitle required>
                      Heure de fin
                    </FormBoldTitle>
                    <FormikDatePicker
                      name="dateFin"
                      time
                    />
                  </div>
                </GridContainer>
              </CaseWorkflowStyledComponents.DialogContent>
              <CaseWorkflowStyledComponents.DialogAction>
                <LongButton
                  variant="outlined"
                  onClick={onClose}
                >
                  Annuler
                </LongButton>
                <LongButton
                  type="submit"
                  variant="contained"
                  disabled={formikForm.isSubmitting}
                >
                  Valider
                </LongButton>
              </CaseWorkflowStyledComponents.DialogAction>
            </Form>
          </Dialog>
      }
    </>
  )
}

export default CaseWorkflowWorksRdvButton
