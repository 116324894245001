/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  CancelRounded,
  CheckCircleRounded,
  CircleRounded,
} from '@mui/icons-material'

/* Type imports ------------------------------------------------------------- */
import { WorkflowEtat } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface DotProps {
  small?: boolean;
}

const Dot = styled.div<DotProps>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.small ? 0 : -33}px;
  margin-right: 10px;

  svg {
    width: ${(props) => props.small ? 0.75 : 1}em;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowDotProps {
  state: WorkflowEtat;
  small?: boolean;
}

const CaseWorkflowDot: React.FC<CaseWorkflowDotProps> = ({ state, small }) => {

  const getDot = () => {
    switch (state) {
      case WorkflowEtat.EnAttente:
        return <CircleRounded color="primary" />
      case WorkflowEtat.Brouillon:
        return <CircleRounded color="primary" />
      case WorkflowEtat.NonFait:
        return <CircleRounded color="info" />
      case WorkflowEtat.Fait:
        return <CheckCircleRounded color="primary" />
      case WorkflowEtat.Annule:
        return <CancelRounded color="error" />
      case WorkflowEtat.Anomalie:
        return <CircleRounded color="warning" />
      default:
        return <CircleRounded color="primary" />
    }
  }

  return (
    <Dot small={small}>
      {getDot()}
    </Dot>
  )
}

export default CaseWorkflowDot
