/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import {
  Tab,
  Tabs,
} from '@mui/material'
import RouteTitle from 'router/RouteTitle'

/* Type declarations -------------------------------------------------------- */
import { settingsTabs } from 'types/Tabs'

/* Component declaration ---------------------------------------------------- */
interface SettingsLayoutNavTabsProps {
  closeDrawer: () => void;
  vertical?: boolean;
}

const SettingsLayoutNavTabs: React.FC<SettingsLayoutNavTabsProps> = ({
  closeDrawer,
  vertical,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { companyId = '' } = useParams<{companyId: string}>()
  const [ tabValue, setTabValue ] = useState<number | boolean>(false)
  const [ title, setTitle ] = useState<string>('')

  useEffect(() => {
    const newTabValue = settingsTabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
    setTabValue(newTabValue)
    if (settingsTabs[newTabValue]?.label) {
      setTitle(`${settingsTabs[newTabValue].label} ${companyId}`)
    }
  }, [ location.pathname ])

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
    navigate(`/parametres/${settingsTabs[newValue].path}/${companyId || ''}`)
    closeDrawer()
  }

  return (
    <>
      <RouteTitle title={title} />
      <Tabs
        value={tabValue === -1 ? false : tabValue}
        orientation={vertical ? 'vertical' : 'horizontal'}
      >
        {
          settingsTabs.map((tab, index) => (
            <Tab
              key={`${tab.path}-${index}`}
              onClick={() => handleTabChange(index)}
              label={tab.label}
            />
          ))
        }
      </Tabs>
    </>
  )
}

export default SettingsLayoutNavTabs
