/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostAnswerMessageMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { EchangeInterne } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const messageSchema = Yup.object({
  message: Yup.string().required('Le message est obligatoire'),
})

type MessageRequest = Yup.InferType<typeof messageSchema>

/* Styled components -------------------------------------------------------- */
const ButtonLine = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    gap: 0px;
    justify-content: space-between;
  }
`

const AnswerHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
  gap: 5px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const MessageTitle = styled.div`
  font-style: italic;
  font-weight: bold;
`

/* Component declaration ---------------------------------------------------- */
interface MessagesAnswerMessageProps {
  message: EchangeInterne;
  closeReply: () => void;
}

const MessagesAnswerMessage: React.FC<MessagesAnswerMessageProps> = ({
  message,
  closeReply,
}) => {

  const [
    submitAnswerMessage,
  ] = usePostAnswerMessageMutation()

  const onSubmit = async (values: MessageRequest, { setSubmitting, resetForm }: FormikHelpers<MessageRequest>): Promise<void> => {
    const response = await submitAnswerMessage({
      id: message.id,
      data: {
        message: values.message || '',
        objet: message.objet,
      },
    })

    if (!isApiError(response)) {
      resetForm()
      closeReply()
    } else {
      setSubmitting(false)
    }
  }

  const formikForm = useForm<MessageRequest>(
    {
      initialValues: {
        message: '',
      },
      onSubmit: onSubmit,
      validationSchema: messageSchema,
    },
  )

  const resetForm = () => {
    formikForm.resetForm()
    closeReply()
  }

  return (
    <LoadingOverlay isLoading={formikForm.isSubmitting}>
      <Form form={formikForm}>
        <AnswerHeader>
          {'Vous répondez au message :'}
          <MessageTitle>
            {message.objet}
          </MessageTitle>
        </AnswerHeader>
        <Field
          component={TextField}
          placeholder="Votre réponse"
          rows={3}
          multiline
          name="message"
          size="small"
        />
        <ButtonLine>
          <LongButton
            variant="outlined"
            onClick={resetForm}
          >
            Annuler
          </LongButton>
          <LongButton
            type="submit"
            variant="contained"
          >
            Répondre
          </LongButton>
        </ButtonLine>
      </Form>
    </LoadingOverlay>
  )
}

export default MessagesAnswerMessage
