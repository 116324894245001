/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'

/* Module imports ----------------------------------------------------------- */
import { usePostDocumentsMutation } from 'store/api'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import ImportFilesModal from 'components/ImportFilesModal/ImportFilesModal'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  DocumentDossierCreatePayload,
  TypeDocument,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowAddDocumentButtonProps {
  caseId: string;
  type: TypeDocument;
}

const CaseWorkflowAddDocumentButton: React.FC<CaseWorkflowAddDocumentButtonProps> = ({
  caseId,
  type,
}) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const [
    submitNewDocument,
    { isLoading: isSendingFile },
  ] = usePostDocumentsMutation()

  const onClose = (files?: File[]) => {
    setOpen(false)
    const data: DocumentDossierCreatePayload = { documentRequests: []}

    if (files && files.length > 0) {
      files.forEach((file) => {
        if (file !== undefined) {
          data.documentRequests?.push({
            fichier: file,
            type,
          })
        }
      })

      submitNewDocument({
        caseId,
        data,
      }).catch(console.error)
    }
  }

  return (
    <React.Fragment>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        disabled={isSendingFile || isReadOnly}
        fullWidth
        onClick={() => setOpen(true)}
      >
        Ajouter le document
      </CaseWorkflowStyledComponents.Button>
      {
        open &&
          <ImportFilesModal
            handleClose={onClose}
            maxFiles={1}
          />
      }
    </React.Fragment>
  )
}

export default CaseWorkflowAddDocumentButton
