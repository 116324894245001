/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useGetLegalDocumentListQuery,
  useGetLegalDocumentTypeListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LegalDocumentsCard from './LegalDocumentsComponents/LegalDocumentsCard'

/* Styled components -------------------------------------------------------- */
const SpaceBetweenContainer = styled.div`
  padding-bottom: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface LegalDocumentPageProps {}

const LegalDocumentPage: React.FC<LegalDocumentPageProps> = () => {
  const {
    currentData: legalDocumentList = [],
    isFetching: isFetchingLegalDocumentList,
  } = useGetLegalDocumentListQuery()

  const {
    currentData: legalDocumentTypeList = [],
    isFetching: isFetchingLegalDocumentTypeList,
  } = useGetLegalDocumentTypeListQuery()

  return (
    <div>
      <LargeTitle>
        Documents Juridiques
      </LargeTitle>
      {
        (isFetchingLegalDocumentList || isFetchingLegalDocumentTypeList) ?
          <CircularProgress /> :
          legalDocumentTypeList.map((legalType) => (
            <SpaceBetweenContainer key={legalType.id}>
              <LegalDocumentsCard
                legalDocument={legalDocumentList.find((doc) => doc.typeDocument?.id === legalType.id)}
                legalType={legalType}
              />
            </SpaceBetweenContainer>
          ))
      }
      <Footer />
    </div>
  )
}

export default LegalDocumentPage
