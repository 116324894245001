/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostContactRdvMutation } from 'store/api'
import {
  isApiError,
  type ApiResponse,
} from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Alert,
  AlertTitle,
  Dialog,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { PriseDeContactRequest } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type Contact = PriseDeContactRequest

const rdvSchema = Yup.object().shape<Shape<Contact>>({
  date: Yup.string().nullable().required('La date est obligatoire'),
  aboutie: Yup.boolean().nullable(),
  commentaire: Yup.string(),
  libelle: Yup.string(),
}).required()

type RdvForm = FormikContextType<Contact>

/* Styled components -------------------------------------------------------- */
const StyledAlert = styled(Alert)`
  margin-top: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowContactRdvButtonProps {
  caseId: string;
  cancelled?: boolean;
}

const CaseWorkflowContactRdvButton: React.FC<CaseWorkflowContactRdvButtonProps> = ({ caseId, cancelled = false }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const [
    submitNewRdv,
  ] = usePostContactRdvMutation()

  const onSubmit = (values: Contact, { setSubmitting, resetForm }: FormikHelpers<Contact>) => {
    const onFinish = (response: ApiResponse<void>) => {
      if (!isApiError(response)) {
        setOpen(false)
        setSubmitting(false)
        resetForm()
      } else {
        setSubmitting(false)
      }
    }

    submitNewRdv({
      caseId,
      data: values,
    }).then(onFinish).catch(console.error)
  }

  const formikForm: RdvForm = useForm<Contact>(
    {
      initialValues: {
        date: new Date().toISOString(),
        aboutie: !cancelled,
        commentaire: '',
        libelle: '',
      },
      onSubmit: onSubmit,
      validationSchema: rdvSchema,
    },
  )

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant={cancelled ? 'outlined' : 'contained'}
        onClick={onClick}
        disabled={isReadOnly}
      >
        {cancelled ? 'Non aboutie' : 'Prise de contact'}
      </CaseWorkflowStyledComponents.Button>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
      >
        <CaseWorkflowStyledComponents.DialogTitle>
          {cancelled ? 'Prise de contact non aboutie' : 'Prise de contact'}
          <CloseButton handleClose={onClose} />
        </CaseWorkflowStyledComponents.DialogTitle>
        <Form form={formikForm}>
          <CaseWorkflowStyledComponents.DialogContent>
            <FormBoldTitle required>
              Date
            </FormBoldTitle>
            <FormikDatePicker name="date" />
            <FormBoldTitle>
              Libellé
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Libellé"
              name="libelle"
            />
            <FormBoldTitle>
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Commentaire"
              name="commentaire"
              multiline
              rows={3}
            />
            {
              cancelled &&
                <StyledAlert
                  variant="outlined"
                  severity="warning"
                >
                  <AlertTitle>
                    Avez-vous laissé un message vocal ?
                  </AlertTitle>
                  Merci de renouveler votre appel sous 48h
                  et de nous tenir informé
                </StyledAlert>
            }
          </CaseWorkflowStyledComponents.DialogContent>
          <CaseWorkflowStyledComponents.DialogAction>
            <LongButton
              variant="outlined"
              onClick={onClose}
            >
              Annuler
            </LongButton>
            <LongButton
              type="submit"
              variant="contained"
              disabled={formikForm.isSubmitting}
            >
              Valider
            </LongButton>
          </CaseWorkflowStyledComponents.DialogAction>
        </Form>
      </Dialog>
    </>
  )
}

export default CaseWorkflowContactRdvButton
