/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowWorksRdvButton from '../CaseWorflowButtons/CaseWorkflowWorksRdvButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type {
  JalonFinDeTravaux,
  JalonFinDeTravauxRDF,
  JalonRendezVousTravaux,
} from 'API/__generated__/Api'
import { WorkflowEtat } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWorksRdvJalonCardProps {
  jalon: JalonRendezVousTravaux;
  caseId: string;
  jalonWorkDone?: JalonFinDeTravaux | JalonFinDeTravauxRDF | null;
}

const CaseWorkflowWorksRdvJalonCard: React.FC<CaseWorkflowWorksRdvJalonCardProps> = ({
  jalon,
  caseId,
  jalonWorkDone,
}) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          RDV travaux
        </CaseWorkflowStyledComponents.Title>
        {
          jalon.etat === WorkflowEtat.EnAttente ?
            <CaseWorkflowWorksRdvButton
              caseId={caseId}
              initialRdv={jalon.rendezVousTravaux?.[0]}
              state={jalon.rendezVousTravaux.length ? WorkflowEtat.Fait : WorkflowEtat.EnAttente}
            /> :
            jalonWorkDone && jalonWorkDone.etat !== WorkflowEtat.Fait && jalonWorkDone.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowWorksRdvButton
                caseId={caseId}
                initialRdv={jalon.rendezVousTravaux?.[0]}
                state={WorkflowEtat.NonAboutie}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.rendezVousTravaux[0]?.dateDebut || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowWorksRdvJalonCard
