/* Module imports ----------------------------------------------------------- */
import { roundNumber } from './helpers/calculs'

/* Type declarations -------------------------------------------------------- */
export interface PrixUnitaireHT {
  prixUnitaireHT: number;
}

export interface PrixUnitaireTTC {
  prixUnitaireTTC: number;
  tauxTVA: number;
}

export interface QuantitePrixHT {
  quantite: number;
  prixUnitaireHT: number;
}

export interface QuantitePrixHTTVA {
  quantite: number;
  prixUnitaireHT: number;
  tauxTVA: number;
}

export interface QuantitePrixTTC {
  quantite: number;
  prixUnitaireTTC: number;
  tauxTVA: number;
}

export interface Vetuste {
  montantTTC: number;
  tauxVetuste: number;
}

export interface VetusteRecuperable {
  montantTTC: number;
  tauxVetuste: number;
  tauxLimiteVetuste: number;
}

/* Function declarations ---------------------------------------------------- */
const getPrixUnitaireHT = ({ prixUnitaireHT }: PrixUnitaireHT): number => {
  return roundNumber(prixUnitaireHT)
}

const getPrixUnitaireHTByPrixUnitaireTTC = ({ prixUnitaireTTC, tauxTVA }: PrixUnitaireTTC): number => {
  return roundNumber(prixUnitaireTTC / (1 + (tauxTVA / 100)))
}

const getMontantHT = ({ quantite, prixUnitaireHT }: QuantitePrixHT): number => {
  return roundNumber(quantite * prixUnitaireHT)
}

const getMontantHTByPrixUnitaireTTC = ({ quantite, prixUnitaireTTC, tauxTVA }: QuantitePrixTTC): number => {
  return roundNumber(quantite * prixUnitaireTTC / (1 + (tauxTVA / 100)))
}

const getMontantTTC = ({ quantite, prixUnitaireHT, tauxTVA }: QuantitePrixHTTVA): number => {
  return roundNumber(quantite * prixUnitaireHT * (1 + (tauxTVA / 100)))
}

const getMontantTTCByPrixUnitaireTTC = ({ quantite, prixUnitaireTTC }: QuantitePrixTTC): number => {
  return roundNumber(quantite * prixUnitaireTTC)
}

const getMontantTVA = ({ quantite, prixUnitaireHT, tauxTVA }: QuantitePrixHTTVA): number => {
  return roundNumber(quantite * prixUnitaireHT * (tauxTVA / 100))
}

const getMontantTVAByPrixUnitaireTTC = ({ quantite, prixUnitaireTTC, tauxTVA }: QuantitePrixTTC): number => {
  return roundNumber((quantite * prixUnitaireTTC) - (quantite * prixUnitaireTTC / (1 + (tauxTVA / 100))))
}

const getMontantVetuste = ({ montantTTC, tauxVetuste }: Vetuste): number => {
  return roundNumber(montantTTC * (tauxVetuste / 100))
}

const getMontantVetusteDeduite = ({ montantTTC, tauxVetuste }: Vetuste): number => {
  return roundNumber(montantTTC - getMontantVetuste({ montantTTC, tauxVetuste }))
}

const getMontantVetusteRecuperable = ({ montantTTC, tauxVetuste, tauxLimiteVetuste }: VetusteRecuperable): number => {
  return roundNumber(montantTTC * (Math.min(tauxVetuste, tauxLimiteVetuste) / 100))
}

export default {
  getPrixUnitaireHT,
  getPrixUnitaireHTByPrixUnitaireTTC,
  getMontantHT,
  getMontantHTByPrixUnitaireTTC,
  getMontantTTC,
  getMontantTTCByPrixUnitaireTTC,
  getMontantTVA,
  getMontantTVAByPrixUnitaireTTC,
  getMontantVetuste,
  getMontantVetusteDeduite,
  getMontantVetusteRecuperable,
}
