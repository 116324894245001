/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowContactRdvButton from '../CaseWorflowButtons/CaseWorkflowContactRdvButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonPriseDeContact } from 'API/__generated__/Api'
import { WorkflowEtat } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowContactJalonCardProps {
  jalon: JalonPriseDeContact;
  caseId: string;
}

const CaseWorkflowContactJalonCard: React.FC<CaseWorkflowContactJalonCardProps> = ({
  jalon,
  caseId,
}) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Prise de contact
        </CaseWorkflowStyledComponents.Title>
        {
          jalon.etat === WorkflowEtat.EnAttente ||
          jalon.etat === WorkflowEtat.NonAboutie ?
            <CaseWorkflowStyledComponents.DuoButtonsContainer>
              <CaseWorkflowContactRdvButton
                caseId={caseId}
                cancelled
              />
              <CaseWorkflowContactRdvButton caseId={caseId} />
            </CaseWorkflowStyledComponents.DuoButtonsContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.priseDeContact?.date || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowContactJalonCard
